import React from 'react'
import File from './file';
import { Download } from '../../types/download'


type FileListProps = {
    items: Download[],
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const FileList: React.FunctionComponent<FileListProps> = ({ items, setRefresh }) => {

    return (
        <div>
            {items.map((item) => {
                return (
                    <File item={item} setRefresh={setRefresh} key={`download-${item.id}`} />
                )
            })}
        </div>
    )
}

export default FileList
