import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Layout } from "../../components";

const IconMock = [
  {
    name: "certificates",
    link: "/catalog/CERTIFICATES",
    image: "certificate.png",
  },
  {
    name: "models_drawing",
    link: "/catalog/VISUAL%203d%20and%20photo",
    image: "3d-model.png",
  },
  {
    name: "catalog_broschure",
    link: "/catalog/CATALOG%20-BROCHURE",
    image: "brochure.png",
  },
  {
    name: "installation_files",
    link: "/catalog/SETUP%20DOCUMENTS",
    image: "setup.png",
  },
  {
    name: "technical_data",
    link: "/catalog/TECHNICAL",
    image: "technical-support.png",
  },
  {
    name: "formulas",
    link: "",
    image: "calculating.png",
  },
  {
    name: "material_info",
    link: "/catalog/MATERIAL%20CATALOG%20+%20INFO",
    image: "material.png",
  },
  {
    name: "pergo_docs_info",
    link: "",
    image: "docs.png",
  },
];

const HomePage: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const RenderItem = ({
    item,
  }: {
    item: { name: string; link: string; image: string };
  }) => {
    return (
      <div
        className={`relative py-4 px-1 rounded-md shadow-lg cursor-pointer transition-all duration-700 bg-white hover:text-white card-item`}
      >
        <Link to={item.link} className="flex items-center gap-5">
          <img
            src={`/images/${item.image}`}
            alt={t(item.name)}
            className="w-9 h-9 object-contain"
          />
          <span className="text-xs font-medium">{t(item.name)}</span>
        </Link>
      </div>
    );
  };

  return (
    <>
      <Layout>
        <h1 className="text-2xl font-semibold mb-5">{t("our_dealers")}</h1>
        <p className="text-sm font-light">{t("our_dealers_text")}</p>

        <div className="grid grid-flow-row grid-cols-4 gap-5 mt-14">
          {IconMock.map((item) => (
            <RenderItem item={item} key={item.name} />
          ))}
        </div>
      </Layout>
    </>
  );
};

export default HomePage;
