import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Formik } from "formik";
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { Alert, Button, Input } from '../../../components'
import { ResponseModel, UserDeclare } from "../../../types";
import { AlertType } from "../../../types/alert";
import { useAuth } from "../../../contexts/auth";
import api from '../../../repository'

const FormLogin: FunctionComponent = () => {
    const { t } = useTranslation();
    const [, setCookie] = useCookies(['token']);
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();


    const [loading, setLoading] = useState(false);
    const [alertActive, setAlertActive] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<AlertType>(AlertType.SUCCESS);

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('field_cannot_be_blank')),
        password: Yup.string().required(t('field_cannot_be_blank')),
    });

    useEffect(() => {
        if (alertActive)
            setTimeout(() => { setAlertActive(false) }, 1450);

    }, [alertActive])

    const onSubmit = async (values: LoginModel) => {
        try {
            setLoading(true);
            const { data } = await api.post<ResponseModel<UserDeclare.UserModel>>(process.env.REACT_APP_LOGIN_SERVICE, values);
            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('login_success_text'));
                setAlertActive(true);
                setCookie('token', data.Data.token, {
                    secure: true,
                });
                login(data.Data);
                setTimeout(() => {
                    if (location.state) {
                        navigate(location.state.from.pathname);
                    } else
                        navigate('/');
                }, 1500);
            }
            setLoading(false);
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setLoading(false);
        }
    };

    const initialValues: LoginModel = {
        username: '',
        password: ''
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                }) => (
                    <>
                        <form className="w-full flex flex-col gap-y-8" onSubmit={handleSubmit}>
                            <Input title={t('username')} Id={"username"} type="text" value={values.username}
                                onChange={handleChange} onBlur={handleBlur}
                                error={touched.username && errors.username} errorMessage={errors.username} />

                            <Input title={t('password')} Id={"password"} type="password" value={values.password}
                                onChange={handleChange} onBlur={handleBlur}
                                error={touched.password && errors.password} errorMessage={errors.password} />

                            <div className="flex justify-end gap-5">
                                <Button appearance="link">
                                    <span>{t('forgot_password')}</span>
                                </Button>
                                <Button onClick={() => handleSubmit()}>
                                    {!loading ?
                                        <span>{t('login_button_text')}</span> :
                                        <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                    }
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </Formik>
            {alertActive && <Alert title="Login" text={alertMessage} type={alertType} />}
        </>
    )
}

interface LoginModel {
    username: string
    password: string

}

export default FormLogin
