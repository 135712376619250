import React, { FunctionComponent } from "react";
import Aside from "./aside";
import Header from "./header";
import Main from "./main";

type LayoutProps = {
  withoutMain?: boolean;
  children?: React.ReactNode;
};

const Layout: FunctionComponent<LayoutProps> = ({ withoutMain, children }) => {
  return (
    <div className="grid grid-flow-row grid-cols-11">
      <aside className="col-span-2">
        <Aside />
      </aside>
      <div className="flex flex-col col-span-9 h-screen overflow-y-scroll">
        <header className="relative mb-20">
          <Header />
        </header>
        {!withoutMain ? <Main>{children}</Main> : children}
        <footer className="mt-10 px-5"></footer>
      </div>
    </div>
  );
};

export default Layout;
