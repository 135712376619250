import { FunctionComponent } from "react"
import { AlertType } from "../../types/alert";

type AlertProps = {
    title: string;
    text?: string;
    type: AlertType;
    relative?: boolean
}

const Alert: FunctionComponent<AlertProps> = ({ title, text, relative, type }) => {
    let alertClass = "";

    switch (type) {
        case 'success':
            alertClass = "bg-green-100 border-green-500 text-green-700"
            break;
        case 'error':
            alertClass = "bg-red-100 border-red-500 text-red-700"
            break;
        case 'info':
            alertClass = "bg-blue-100 border-blue-500 text-blue-700"
            break;
        case 'warning':
            alertClass = "bg-yellow-100 border-yellow-500 text-yellow-700"
            break;
    }
    return (
        <div className={`${relative ? "relative border rounded-md" : "fixed top-5 right-5 border-l-8"} p-4 pr-10 ${alertClass}`} role="alert">
            <p className="font-bold">{title}</p>
            {text && <p>{text}</p>}
        </div>
    )
}

export default Alert
