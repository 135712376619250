import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from "yup";
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { FiArrowLeft } from 'react-icons/fi'
import { Alert, Input, Button, File, } from '../..'
import { AlertType, ResponseModel, } from '../../../types'
import { Download } from '../../../types/download';
import api from '../../../repository';

type AddFileProps = {
    id: number;
    title: string;
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>

}

const AddFile: FunctionComponent<AddFileProps> = ({ id, title, active, setActive }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [alertActive, setAlertActive] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);

    const validationSchema = Yup.object().shape({
        language: Yup.string().required(t('field_cannot_be_blank')),
    });

    React.useEffect(() => {
        if (alertActive)
            setTimeout(() => { setAlertActive(false) }, 1450);

    }, [alertActive])

    const onSubmit = async (values: Download, resetForm: any) => {
        try {
            setLoading(true);
            if (values.drivelink !== "") {
                values.filename = values.filename2;
            }
            const { data } = await api.post<ResponseModel<Download>>(process.env.REACT_APP_CREATE_DOWNLOAD_SERVICE, values);
            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage('Dosya Eklendi');
                setAlertActive(true);
            }
            setLoading(false);
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setLoading(false);
        }
    };

    const initialValues: Download = {
        catalogId: id,
        language: "",
        drivelink: '',
        filename2: '',
        downloadbinary: '',
        contenttype: "",
        filename: "",
        filesize: "",
        extension: ""
    }

    return (
        <>
            <div className={`fixed transition-transform duration-500 transform  ${active ? "-translate-x-2/4 opacity-25" : "translate-x-full opacity-0"} right-0 top-0 bg-black h-full w-2/3 z-50`} onClick={() => setActive(false)} />
            <div className={`fixed transition duration-500 transform  ${active ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"} right-0 top-0 bg-white h-full w-1/3 z-50 shadow-md p-5`}>
                <div className="flex justify-between gap-x-4 items-center pb-3 border-b">
                    <div className="p-2 rounded-full bg-white shadow hover:bg-gray-50" onClick={() => setActive(false)}>
                        <FiArrowLeft size={30} className="cursor-pointer" />
                    </div>
                    <h2 className="text-2xl font-bold">{title}</h2>
                </div>
                <div className="mt-5">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue
                        }) => (
                            <>
                                <form className="w-full flex flex-col gap-8 mt-10" onSubmit={handleSubmit}>
                                    <div>
                                        <select className="border w-full h-10 rounded-sm px-4 focus:outline-none focus:border-purple-700 transition-colors"
                                            id="language"
                                            name={"language"}
                                            onChange={handleChange}>
                                            <option value="">{t('select_language')}</option>
                                            <option value="Türkçe">Türkçe</option>
                                            <option value="English">English</option>
                                            <option value="Deutsch">Deutsch</option>
                                            <option value="Italia">Italia</option>
                                            <option value="French">French</option>
                                            <option value="Japanese">Japanese</option>
                                            <option value="Spanish">Spanish</option>
                                            <option value="Arabic">Arabic</option>
                                            <option value="Mother tongue">Mother tongue</option>
                                        </select>
                                        {touched.language && errors['language'] &&
                                            <div className="mt-1">
                                                <span className="text-xs text-red-600">{errors['language']}</span>
                                            </div>
                                        }
                                    </div>

                                    <div>
                                        <File Id="downloadbinary" name={`downloadbinary-${id}`} setFieldValue={setFieldValue} />
                                        {touched.downloadbinary && errors['downloadbinary'] &&
                                            <div className="mt-1">
                                                <span className="text-xs text-red-600">{errors['downloadbinary']}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className='flex flex-col gap-5'>
                                        <Input title={"File Name"} Id={"filename2"} type="text"
                                            value={values.filename2}
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={touched.filename2 && errors.filename2}
                                            errorMessage={errors.filename2} />
                                        <Input title={"Drive Link"} Id={"drivelink"} type="text"
                                            value={values.drivelink}
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={touched.drivelink && errors.drivelink}
                                            errorMessage={errors.drivelink} />
                                        <p className='mt-5 text-xs text-red-700 leading-5'>
                                            Not: Eğer dosya boyutu 20MB'dan büyükse "Drive Link" alanını doldurun <b>Dosya eklemesi yapmayın.</b>
                                        </p>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button onClick={() => handleSubmit()}>
                                            {!loading ?
                                                <span>{t('add')}</span> :
                                                <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )}
                    </Formik>
                    {alertActive && <Alert title="Catalog" text={alertMessage} type={alertType} />}
                </div>
            </div >
        </>
    )
}

export default AddFile
