import React from 'react'

const UnAuthorized = () => {
    return (
        <div className="flex justify-center items-center my-10">
            <div className="bg-red-100 border-red-500 text-red-700 px-10 py-3 rounded-md w-full text-center">
                <span className="font-semibold">Bu sayfayı görmeye yetkiniz yok!</span>
            </div>
        </div>
    )
}

export default UnAuthorized
