import React from 'react'

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router'
import { BsFilePdf } from 'react-icons/bs';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import moment from 'moment';

import { Loading, UnAuthorized } from '../../../components';
import { ResponseModel } from '../../../types';
import { CatalogModel } from '../../../types/catalog';
import api from '../../../repository';

type Group = {
    type: string;
    data: CatalogModel.Catalog[]
}

const List = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const emptyCatalogList: Group[] = [] as Group[];
    const [loading, setLoading] = React.useState<boolean>(true);
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
    const [catalogs, setCatalogs] = React.useState<Group[]>(emptyCatalogList);


    React.useEffect(() => {
        if (refresh) {
            GetCatalog();
        }
    }, [refresh]);

    const GetCatalog = async () => {
        try {

            const { data } = await api.get<ResponseModel<CatalogModel.Catalog[]>>(process.env.REACT_APP_CATALOG_TYPES_SERVICE);
            if (data.Success) {
                let newGroup: Group[] = [];
                data.Data.map((item) => {
                    const find = newGroup.find((f) => f.type === item.type);
                    if (!find) {
                        return newGroup.push({
                            type: item.type,
                            data: [item],
                        });
                    } else {
                        return null;
                    }
                });
                setCatalogs(newGroup);
            }
            setRefresh(false);
            setLoading(false);
        } catch (error) {
            setUnauthorized(true);
            setLoading(false);
            setRefresh(false);
        }
    }

    return (
        <>
            {!loading ?
                <>
                    {!unauthorized ?
                        <div className="grid grid-flow-row grid-cols-4 gap-5 mt-10">
                            {catalogs.map((item) => {
                                return <React.Fragment key={`catalog-${item.type}`}>
                                    {
                                        item.data.map((catalog) => {
                                            return <React.Fragment key={`file-${catalog.id}`}>
                                                <div className="border p-2 rounded-sm" onClick={() => navigate(`/catalog/${catalog.type}`)}>
                                                    <div className="relative bg-gray-300 flex items-center justify-center w-full h-36 cursor-pointer">
                                                        <HiOutlineDotsVertical size={25} className="absolute right-1 top-3" />
                                                        <BsFilePdf size={40} />
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="font-semibold mb-1 cursor-pointer">{catalog.type}</p>
                                                        <p className="text-xs text-gray-400 mt-2">{t('last_updated')} {moment(catalog.createdAt).startOf('hour').fromNow()}</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })
                                    }
                                </React.Fragment>

                            })}
                        </div> :
                        <UnAuthorized />
                    }
                </> :
                <Loading />
            }
        </>
    )
}

export default List
