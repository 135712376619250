import React, { FunctionComponent } from "react"

type InputProps = {
    Id: string,
    title: string,
    value: string,
    type?: React.HTMLInputTypeAttribute,
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    error?: boolean | string;
    errorMessage?: string
}

const Input: FunctionComponent<InputProps> = ({ Id, title, value, type = "text", error, errorMessage, onChange, onBlur, }) => {
    return (
        <div className="flex flex-col relative">
            <label htmlFor={Id} className="absolute left-2 -top-2 bg-white max-w-max text-sm px-3 font-light">
                {title}
            </label>
            <input className="border w-full h-10 rounded-sm px-4 focus:outline-none focus:border-purple-700 transition-colors"
                type={type} name={Id} id={Id} onChange={onChange} onBlur={onBlur} value={value} />
            {error &&
                <div className="right-3 top-1/4 absolute">
                    <span className="text-xs text-red-600">{errorMessage}</span>
                </div>
            }
        </div>
    )
}

export default Input
