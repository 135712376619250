import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom';
import { UnAuthorized, Loading, Button } from '../../../components';
import { ResponseModel, UserList } from '../../../types';
import api from '../../../repository'

const List: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const emptyUserList: UserList.UserListModel[] = [] as UserList.UserListModel[];
    const [loading, setLoading] = React.useState<boolean>(true);
    const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
    const [users, setUsers] = React.useState<UserList.UserListModel[]>(emptyUserList);
    const navigate = useNavigate();

    React.useEffect(() => {
        GetUser();
    }, []);

    const GetUser = async () => {
        try {
            const { data } = await api.get<ResponseModel<UserList.UserListModel[]>>(process.env.REACT_APP_USER_LIST_SERVICE);
            if (data.Success) {
                setUsers(data.Data);
            }
            setLoading(false);
        } catch (error) {
            setUnauthorized(true);
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            {!loading ?
                <React.Fragment>
                    {!unauthorized ?
                        <div className="flex flex-col">
                            <div className="flex justify-between mb-3">
                                <h1 className="text-2xl font-semibold mb-5">{t('users')}</h1>
                                <Button title={t('add_new')} onClick={() => navigate('/user/create')} />
                            </div>
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {t('id')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {t('name_surname')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {t('status')}
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                        >
                                            {t('role')}
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">{t('edit')}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {users.map((user) => {
                                        const splitedFullName = user.fullname.split(' ');
                                        const smallName = `${splitedFullName[0]?.substr(0, 1)}${splitedFullName[1]?.substr(0, 1) ? splitedFullName[1]?.substr(0, 1) : ""}`;

                                        return (

                                            <tr key={user.email}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex h-10 w-10 bg-gray-300 rounded-full items-center justify-center text-xs">
                                                            {smallName.toLocaleUpperCase()}
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{user.username}</div>
                                                            <div className="text-sm text-gray-500">{user.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{user.fullname}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {user.isactive ?
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            {t('active')}
                                                        </span> :
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                                                            {t('passive')}
                                                        </span>}

                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.roles[0].name}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <Link to={`/user/edit/${user.id}`} className="text-indigo-600 hover:text-indigo-900">
                                                        {t('edit')}
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> :
                        <>
                            <h1 className="text-2xl font-semibold mb-5">User Page</h1>
                            <UnAuthorized />
                        </>
                    }

                </React.Fragment> : <Loading />}

        </React.Fragment>
    )
}

export default List
