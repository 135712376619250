import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router'
import { Alert, Button, Layout, Loading, UnAuthorized } from '../../../components'
import { AddnewUserAttributes, AlertType, ResponseModel } from '../../../types';
import Form from './form';
import api from '../../../repository'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const UserEditPage: React.FunctionComponent = () => {
    const emptyUser: AddnewUserAttributes = {} as AddnewUserAttributes;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userid } = useParams();
    const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<AddnewUserAttributes>(emptyUser);
    const [alertActive, setAlertActive] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);


    React.useEffect(() => {
        GetUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetUser = async () => {
        try {
            setLoading(true);
            const { data } = await api
                .get<ResponseModel<AddnewUserAttributes>>(`${process.env.REACT_APP_USER_DETAIL_SERVICE}/${userid}`);

            if (data.Success) {
                setUser(data.Data)
            }
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            setUnauthorized(true);
        }
    }

    const deleteUser = async () => {
        try {
            setDeleteLoading(true);
            const { data } = await api
                .delete<ResponseModel<AddnewUserAttributes>>(`${process.env.REACT_APP_USER_SERVICE}/${user.id}`);

            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('registration_deleted'));
                setAlertActive(true);

                setTimeout(() => {
                    navigate('/user');
                }, 1500);
            }
            setDeleteLoading(false);
        } catch (err: any) {
            setDeleteLoading(false);
            setAlertType(AlertType.ERROR);
            setAlertMessage(t('registration_deleted'));
            setAlertActive(true);
        }
    }

    return (
        <>
            <Layout>
                <div className="flex flex-col">
                    <div className="flex justify-between mb-3">
                        <h1 className="text-2xl font-semibold mb-5">{t('user')} | {user.fullname}</h1>
                        <div className="flex flex-wrap gap-x-3">
                            <Button title={t('turn_back')} appearance="secondary" onClick={() => navigate('/user')}>
                                <FiArrowLeft size={20} />
                                <span className="ml-1">{t('turn_back')}</span>
                            </Button>
                            {!loading && !unauthorized &&
                                <Button appearance="danger" onClick={() => deleteUser()}>
                                    {!deleteLoading ?
                                        <span>{t('delete')}</span>
                                        : <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                    }
                                </Button>
                            }
                        </div>
                    </div>
                    {!loading ? <>{!unauthorized ? <Form user={user} /> : <UnAuthorized />}</> : <Loading />}
                    {alertActive && <Alert title="User" text={alertMessage} type={alertType} />}
                </div>
            </Layout>
        </>
    )
}

export default UserEditPage

