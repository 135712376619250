export const TRANSLATIONS_EN = {
  our_dealers: "Our dealers ;",
  our_dealers_text:
    "It is a platform where technical details, models, materials library to facilitate operational processes, data that will facilitate project processes, and more data can be accessed. Contact us to enter this platform that will provide many advantages.",
  login: "Login",
  field_cannot_be_blank: "This field cannot be left blank",
  valid_emailaddress_error: "Please enter a valid e-mail address",
  record_created: "Record Created",
  login_success_text: "Login successful, you are redirected",
  username: "Username",
  password: "Password",
  login_button_text: "Login",
  dashboard: "Dashboard",
  users: "Users",
  catalogs: "Documents",
  system_settings: "System Settings",
  logout: "Logout",
  add_new: "Add New",
  name: "Name",
  type: "Type",
  size: "Size",
  name_surname: "Full Name",
  status: "Status",
  role: "Role",
  edit: "Edit",
  active: "Active",
  passive: "Passive",
  add_new_user: "Add New User",
  turn_back: "Turn Back",
  add: "Add",
  email: "E-Mail",
  save: "Save",
  updated_record: "Updated Record",
  registration_deleted: "Registration Deleted",
  user: "User",
  delete: "Delete",
  add_catalog: "Add Document",
  languages_availabe: "{{count}} Different Documents Available",
  last_updated: "Last Updated :",
  add_file: "Add File",
  file_not_attached: "File not attached",
  attach_file: "Please attach file",
  select_language: "Select Language",
  catalog_added: "Document Added",
  add_new_catalog: "Add New Document",
  catalog_download_list: "Document Download List",
  message: "Message",
  date: "Date",
  id: "Id",
  ip: "Ip",
  catalog_error_list: "System Errors",
  source: "Source",
  user_log_list: "User Login Logs",
  forgot_password: "Forgot Password",
  change_password: "Change Password",
  updated_password: "Updated Password",
  modal_warning_text:
    "It is forbidden to share documents and information on this platform with third parties.",
  main_file: "Main Folder",
  not_accept: "I do not accept",
  agree: "I agree",
  bank_info: "Bank information",
  certificates: "Certificates",
  models_drawing: "MODELS - DRAWINGS (2D-3D)",
  catalog_broschure: "CATALOG-BROCHURE",
  installation_files: "INSTALLATION FILES",
  technical_data: "TECHNICAL DATA",
  formulas: "CALCULATIONS-FORMULAS",
  material_info: "MATERIAL INFORMATION",
  pergo_docs_info: "DOCUMENTS ON PERGOTECH GLOBAL",
};
