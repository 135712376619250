import axios from "axios";
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next";
import { Modal } from "../../components"
import { RightContainer, LeftContainer } from "./components"

const LoginPage = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = React.useState<boolean>(true);

    const failureFunc = () => {
        if (document) {
            document.location = "https://www.pergotech.com.tr/tr";
        }
    }

    useEffect(() => {
        axios.get('http://ficspergotechwebapi:100/api/Common/GetLanguage', {
            headers: {
                slug: 'test',
                'Access-Control-Allow-Origin': '*'
            }
        }).then((e) => {
            console.log(e);
        }).catch((e) => {
            console.log(e)
        })
    }, []);

    return (
        <>
            <div className="w-full h-screen flex items-center justify-center">
                <div className="absolute top-0 left-0 z-0 w-full h-full">
                    <div className="bg-black w-full h-full absolute opacity-50" />
                    <img src={'https://files.pergotech.com.tr/content/images/sliders/home-banner-slider-1.webp'} alt="Login" className="w-full h-full object-cover" />
                </div>
                <div className="flex flex-wrap w-4/5 h-96 bg-white relative z-10 rounded-2xl shadow-lg">
                    <div className="hidden md:flex w-2/5 relative h-full">
                        <RightContainer />
                    </div>
                    <div className="w-full md:w-3/5 px-5 md:px-10 py-5 flex flex-wrap">
                        <LeftContainer />
                    </div>
                </div>
            </div>
            {showModal && <Modal
                title={t('modal_warning_text')}
                failureFunc={() => failureFunc()}
                successFunc={() => setShowModal(false)} />}

        </>
    )
}


export default LoginPage
