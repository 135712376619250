import React from "react"

type MainProps = {
    children: React.ReactNode
}

const Main: React.FunctionComponent<MainProps> = ({ children }) => {
    return (
        <div className="px-5 relative mt-10">
            <div className="bg-white p-5 shadow-md rounded-md">
                {children}
            </div>
        </div>
    )
}

export default Main
