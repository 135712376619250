import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const List = () => {
    const { t } = useTranslation();
    return (
        <>
            <ul className="grid grid-flow-row grid-cols-3 gap-5">
                <li className='flex'>
                    <Link to="/system/userlist" className='p-3 bg-gray-50 transition-all hover:bg-gray-100 w-full rounded'>
                        <span>{t('user_log_list')}</span>
                    </Link>
                </li>
                <li className='flex'>
                    <Link to="/system/downloadlist" className='p-3 bg-gray-50 transition-all hover:bg-gray-100 w-full rounded'>
                        <span>{t('catalog_download_list')}</span>
                    </Link>
                </li>
                <li className='flex'>
                    <Link to="/system/errorlist" className='p-3 bg-gray-50 transition-all hover:bg-gray-100 w-full rounded'>
                        <span>{t('catalog_error_list')}</span>
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default List
