import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose, AiOutlineFile, AiOutlineFileExcel } from 'react-icons/ai';
import { BsFilePdf } from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import { GrDocumentCsv } from 'react-icons/gr'

type FileProps = {
    Id: string,
    name: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const File: React.FunctionComponent<FileProps> = ({ Id, name, setFieldValue }) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = React.useState<File>();

    const changeHandler = async (event: any) => {
        const file: File = event.target.files[0];
        console.log(file)
        setSelectedFile(file);
        const base64: any = await toBase64(file);
        if (base64) {
            if (setFieldValue) {
                setFieldValue(Id, base64);
                setFieldValue('filename', file?.name);
                setFieldValue('filesize', file?.size.toString());
                setFieldValue('extension', file?.type);
                setFieldValue('contenttype', file?.type);
            };
        }
    };

    const toBase64 = (file: File) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => {
            console.log(error);
            reject(error)
        };
    });

    const bytesToSize = (bytes: number, decimals: number = 2): string => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const GetIcon = (key: string) => {
        switch (key) {
            case "application/pdf":
                return <BsFilePdf size={50} />
            case ".csv":
                return <GrDocumentCsv size={50} />
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <AiOutlineFileExcel size={50} />
            default:
                return <AiOutlineFile size={50} />
        }
    }

    return (
        <div>
            <div>
                <label className="flex justify-center items-center gap-x-3 border p-2" htmlFor={name}>
                    <FiUploadCloud size={40} />
                    <span>{t('add_file')}</span>
                </label>
                <input id={name}
                    className="opacity-0 w-0 h-0"
                    type="file"
                    name={Id}
                    //accept="application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={changeHandler} />
            </div>
            <div>
                {selectedFile && <>
                    <div className="relative flex items-center gap-x-6 mt-5 border p-2">
                        <div>
                            {GetIcon(selectedFile.type)}
                        </div>
                        <div>
                            <p><b>{t('name')} :</b> {selectedFile.name}</p>
                            <p><b>{t('type')} :</b> {selectedFile.type}</p>
                            <p><b>{t('size')} :</b> {bytesToSize(selectedFile.size)}</p>
                        </div>
                        <div className="absolute right-2 top-2 cursor-pointer">
                            <AiOutlineClose size={25} className="hover:text-red-600"
                                onClick={() => setSelectedFile(undefined)} />
                        </div>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default File;