import React from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineClose, AiOutlineFileExcel } from 'react-icons/ai'
import { BsFilePdf } from 'react-icons/bs'
import { FiDownload } from 'react-icons/fi'
import { GrDocumentCsv } from 'react-icons/gr'
import { Loading } from '..'
import { useAuth } from '../../contexts/auth'
import { ResponseModel } from '../../types'
import { Download } from '../../types/download'
import { Roles } from '../../types/roles'
import api from '../../repository';

type FileProps = {
    item: Download;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const File: React.FunctionComponent<FileProps> = ({ item, setRefresh }) => {
    const { t } = useTranslation();
    const { user: { roles } } = useAuth();
    const isAdmin = roles.find((role) => role.name === Roles.ADMIN);
    const [loading, setLoading] = React.useState<boolean>(false);

    const bytesToSize = (bytes: number, decimals: number = 2): string => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const GetIcon = (key: string) => {
        switch (key) {
            case "application/pdf":
                return <BsFilePdf size={50} />
            case ".csv":
                return <GrDocumentCsv size={50} />
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return <AiOutlineFileExcel size={50} />
        }
    }

    const RemoveFile = async (id: number | undefined) => {
        try {
            setLoading(true);
            const { data } = await api.delete<ResponseModel<Download>>(`${process.env.REACT_APP_DOWNLOAD_SERVICE}/${id}`);
            if (data.Success) {
                setRefresh(true);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    function arrayBufferToBase64(buffer: any | string): string {
        try {
            if (typeof buffer !== "string") {
                let binary = '';
                let bytes = new Uint8Array(buffer.data);
                let len = bytes.byteLength;
                for (let i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                return window.btoa(binary);
            } else
                throw new Error("");
        } catch (error) {
            console.log(error);
            return "";
        }
    }

    const downloadBase64File = async (id: number | undefined) => {
        try {
            setLoading(true);
            const { data } = await api.get<ResponseModel<Download>>(`${process.env.REACT_APP_DOWNLOAD_SERVICE}/${id}`);
            if (data.Success) {
                const base64 = arrayBufferToBase64(data.Data.downloadbinary);
                const linkSource = `data:application/text;base64,${base64}`;
                const dataText = await fetch(linkSource);
                const text = await dataText.text();
                const downloadLink = document.createElement("a");

                downloadLink.href = text;
                downloadLink.download = data.Data.filename;
                downloadLink.click();
                downloadLink.remove();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <div className="relative flex items-center gap-x-6 mt-5 border p-2">
            <div>
                {GetIcon(item.contenttype)}
            </div>
            <div className="pr-4">
                <p className="text-lg mb-1 font-bold">{item.language}</p>
                <p><b className="font-medium">{t('name')} :</b> {item.filename}</p>
                {item.drivelink === null && <>
                    <p><b className="font-medium">{t('type')} :</b> {item.contenttype}</p>
                    <p><b className="font-medium">{t('size')} :</b> {bytesToSize(parseInt(item.filesize))}</p>
                </>}
            </div>
            <div className="absolute right-2 top-1 cursor-pointer">
                {isAdmin && <AiOutlineClose size={25} className="hover:text-red-600 mb-4"
                    onClick={() => RemoveFile(item.id)}
                />}
                {item.id !== undefined && item.drivelink === null && <FiDownload size={25} className="hover:text-brand-purple"
                    onClick={() => downloadBase64File(item.id)} />
                }
                {item.drivelink !== null &&
                    <a href={item.drivelink} target="_blank" rel='noreferrer'>
                        <FiDownload size={25} className="hover:text-brand-purple" />
                    </a>
                }
            </div>
            {loading &&
                <div className="absolute top-0 left-0 w-full h-full">
                    <div className="absolute z-50 w-full h-full bg-black opacity-30" />
                    <Loading size="w-14 h-14" color="border-black" />
                </div>
            }
        </div>
    )
}

export default File

// TODO Drive link click eventi olduğunda log atılcak.