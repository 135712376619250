import React, { Suspense } from 'react';
import {
  BrowserRouter,
} from "react-router-dom";
import Router from './router';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './contexts/auth';
import reportWebVitals from './reportWebVitals';
import './i18n';
import './css/index.css'



ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <CookiesProvider>
        <AuthProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AuthProvider>
      </CookiesProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
