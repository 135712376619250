import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from ".."

type Props = {
    title: string,
    failureFunc?: () => void
    successFunc?: () => void
}

const Modal: React.FC<Props> = ({ title, failureFunc, successFunc }) => {
    const { t } = useTranslation()
    return (
        <div className='fixed top-0 left-0 w-full h-full z-20 flex items-center justify-center'>
            <div className='bg-black absolute left-0 top-0 w-full h-full opacity-60' />
            <div className='relative max-w-2xl z-10 bg-white p-5 shadow-md rounded-md'>
                <p className="text-center">
                    {title}
                </p>
                <div className="mt-5 w-full flex items-center justify-center gap-5">
                    <Button appearance="danger" title={t('not_accept')} onClick={failureFunc} />
                    <Button appearance="success" title={t('agree')} onClick={successFunc} />
                </div>
            </div>

        </div>
    )
}

export default Modal
