import React from "react";
import moment from "moment";
import "moment/min/locales";
import { FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Layout, Loading, UnAuthorized } from "../../../components";
import { ResponseModel } from "../../../types";
import { SystemModel } from "../../../types/system";
import api from "../../../repository";

const SystemErrorListPage: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const emptyerrorCatalogList: SystemModel.ErrorLog[] =
    [] as SystemModel.ErrorLog[];
  const [loading, setLoading] = React.useState<boolean>(true);
  const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
  const [errorList, setErrorList] = React.useState<SystemModel.ErrorLog[]>(
    emptyerrorCatalogList
  );

  moment.locale(i18n.language);

  React.useEffect(() => {
    GetErrorLog();
  }, []);

  const GetErrorLog = async () => {
    try {
      const { data } = await api.get<ResponseModel<SystemModel.ErrorLog[]>>(
        process.env.REACT_APP_ERROR_LOG_SERVICE
      );
      if (data.Success) {
        setErrorList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setUnauthorized(true);
      setLoading(false);
    }
  };
  return (
    <Layout>
      {!loading ? (
        <>
          {!unauthorized ? (
            <div className="flex flex-col">
              <div className="flex justify-between mb-3">
                <h1 className="text-2xl font-semibold mb-5">
                  {t("catalog_error_list")}
                </h1>
                <Button
                  title={t("turn_back")}
                  appearance="secondary"
                  onClick={() => navigate("/system")}
                >
                  <FiArrowLeft size={20} />
                  <span className="ml-1">{t("turn_back")}</span>
                </Button>
              </div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("id")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("message")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("source")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("date")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("ip")}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {errorList.map((error) => {
                    return (
                      <tr key={error.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {error.id}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {error.message}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {error.querystring}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {moment(error.logdatetimeutc).fromNow()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {error.ip}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <UnAuthorized />
          )}
        </>
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default SystemErrorListPage;
