import { Language } from "../types/language";
import { Menu, MenuIconTypes } from "../types/menu";
import { Roles } from "../types/roles";

const MenuMockup: Menu[] = [
  {
    name: "dashboard",
    to: "/",
    icon: MenuIconTypes.HOME,
    role: Roles.USER,
  },
  {
    name: "users",
    to: "/user",
    icon: MenuIconTypes.USER,
    role: Roles.ADMIN,
  },
  {
    name: "catalogs",
    to: "/catalog",
    icon: MenuIconTypes.CATALOG,
    role: Roles.USER,
  },
  {
    name: "bank_info",
    to: "/bankinfo",
    icon: MenuIconTypes.BANK,
    role: Roles.USER,
  },
  {
    name: "system_settings",
    to: "/system",
    icon: MenuIconTypes.SETTING,
    role: Roles.ADMIN,
  },
];

const LanguageMockup: Language[] = [
  {
    name: "Türkçe",
    seocode: "tr",
    image: "/images/tn_tu-flag.gif",
  },
  {
    name: "English",
    seocode: "en",
    image: "/images/tn_uk-flag.gif",
  },
];

export { MenuMockup, LanguageMockup };
