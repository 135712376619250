import { useCookies, } from 'react-cookie';
import { useTranslation } from 'react-i18next'
import { FiLogOut, } from 'react-icons/fi'
import { useNavigate } from 'react-router';
import { useAuth } from '../../../contexts/auth'
import { LanguageMockup } from '../../../mockup';

const Header = () => {
    const [, , removeCookie] = useCookies(['token']);
    const { t, i18n } = useTranslation();
    const { signout, user } = useAuth();
    const navigate = useNavigate();
    const activeLang = LanguageMockup.find((lang) => lang.seocode === i18n.language);

    const logOut = () => {
        removeCookie('token');
        signout();
        navigate('/login');
    }

    const splitedFullName = user.fullname.split(' ');
    const smallName = `${splitedFullName[0]?.substr(0, 1)}${splitedFullName[1]?.substr(0, 1) ? splitedFullName[1]?.substr(0, 1) : ""}`;
    return (
        <div className="absolute top-0 w-full flex flex-wrap items-center justify-end bg-gradient-to-l to-brand-purple from-brand-pink px-5 pb-36">
            <div className='group relative flags mr-5 transition-all duration-700 hover:bg-hover-bg py-5 px-3 cursor-pointer'>

                <div>
                    <img src={activeLang?.image} alt={activeLang?.name} className='w-10 h-10 object-contain' />
                </div>
                <div className='absolute transition-all bg-white flex flex-col
                                z-30 left-0 invisible  top-full w-44 shadow-md rounded-md opacity-0 
                                group-hover:visible
                                group-hover:opacity-100'>

                    {LanguageMockup.map((lang) => {
                        return (
                            <button key={`lang-${lang.name}`} className='flex items-center gap-4 p-3 rounded-md hover:bg-brand-pink hover:text-white'
                                onClick={() => i18n.changeLanguage(lang.seocode)}>
                                <img src={lang.image} alt={lang.name} width={30} height={20} />
                                <span className='text-sm font-semibold'>{lang.name}</span>
                            </button>
                        )
                    })}
                </div>
            </div>
            <div className="flex gap-x-2 items-center text-white mr-10 cursor-pointer">
                <div className="flex items-center" onClick={() => logOut()}>
                    <FiLogOut size={25} />
                    <span className="text-xs ml-1">{t('logout')}</span>
                </div>
            </div>
            <div className="flex flex-wrap gap-x-3 items-center">
                <div className="flex flex-col text-white">
                    <span className="font-bold">{user.username}</span>
                    <span className="text-xs">{user.fullname}</span>
                </div>
                <div className="bg-white rounded-full w-10 h-10 flex items-center justify-center shadow-md">
                    {smallName}
                </div>
            </div>

        </div >
    )
}

export default Header
