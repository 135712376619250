import React from 'react'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router'
import * as Yup from "yup";
import { Alert, Button, Checkbox, Input, Layout } from '../../../components'
import { AddnewUserAttributes, AlertType, ResponseModel, UserDeclare } from '../../../types';
import api from '../../../repository'

const UserCreatePage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [alertActive, setAlertActive] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('field_cannot_be_blank')),
        password: Yup.string().required(t('field_cannot_be_blank')),
        fullname: Yup.string().required(t('field_cannot_be_blank')),
        email: Yup.string().email(t('valid_emailaddress_error')).required(t('field_cannot_be_blank')),
    });

    React.useEffect(() => {
        if (alertActive)
            setTimeout(() => { setAlertActive(false) }, 1450);

    }, [alertActive])

    const onSubmit = async (values: AddnewUserAttributes, resetForm: any) => {
        try {
            setLoading(true);
            const { data } = await api.post<ResponseModel<UserDeclare.UserModel>>(process.env.REACT_APP_SIGN_IN_SERVICE, values);
            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('record_created'));
                setAlertActive(true);
            }
            setLoading(false);
            resetForm();
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setLoading(false);
        }
    };

    const initialValues: AddnewUserAttributes = {
        username: '',
        password: '',
        fullname: '',
        email: '',
        isactive: false
    }
    return (
        <>
            <Layout>
                <div className="flex flex-col">
                    <div className="flex justify-between mb-3">
                        <h1 className="text-2xl font-semibold mb-5">{t('add_new_user')}</h1>
                        <div className="flex flex-wrap gap-x-3">
                            <Button title={t('turn_back')} appearance="secondary" onClick={() => navigate('/user')}>
                                <FiArrowLeft size={20} />
                                <span className="ml-1">{t('turn_back')}</span>
                            </Button>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            setFieldValue
                        }) => (
                            <>
                                <form className="w-full grid grid-flow-row grid-cols-2 gap-8 mt-10" onSubmit={handleSubmit}>
                                    <Input title={t('username')} Id={"username"} type="text" value={values.username}
                                        onChange={handleChange} onBlur={handleBlur}
                                        error={touched.username && errors.username} errorMessage={errors.username} />

                                    <Input title={t('password')} Id={"password"} type="password" value={values.password}
                                        onChange={handleChange} onBlur={handleBlur}
                                        error={touched.password && errors.password} errorMessage={errors.password} />

                                    <Input title={t('name_surname')} Id={"fullname"} type="text" value={values.fullname}
                                        onChange={handleChange} onBlur={handleBlur}
                                        error={touched.fullname && errors.fullname} errorMessage={errors.fullname} />

                                    <Input title={t('email')} Id={"email"} type="text" value={values.email}
                                        onChange={handleChange} onBlur={handleBlur}
                                        error={touched.email && errors.email} errorMessage={errors.email} />

                                    <Checkbox title={t('active')} Id={"isactive"}
                                        checked={values.isactive}
                                        setFieldValue={() => setFieldValue("isactive", !values.isactive)}
                                        onChange={handleChange}
                                        error={touched.isactive && errors.isactive} errorMessage={errors.isactive} />

                                    <div className="col-span-2 flex justify-end">
                                        <Button onClick={() => handleSubmit()}>
                                            {!loading ?
                                                <span>{t('add')}</span> :
                                                <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )}
                    </Formik>
                    {alertActive && <Alert title="User" text={alertMessage} type={alertType} />}
                </div>
            </Layout>
        </>
    )
}

export default UserCreatePage

