import React from "react";
import { Navigate, useLocation } from "react-router";
import { useCookies } from "react-cookie";
import { useAuth } from "../contexts/auth";
import api from '../repository';
import { ResponseModel, UserDeclare } from "../types";
import { Loading } from "../components";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [isLogin, setIsLogin] = React.useState<boolean>(true);
    const auth = useAuth();
    const location = useLocation();
    const [cookie, , removeToken] = useCookies(['token']);

    React.useEffect(() => {
        checkToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkToken = async () => {
        try {
            const postData = {
                token: cookie.token
            }
            const { data } = await api.post<ResponseModel<UserDeclare.UserModel>>(process.env.REACT_APP_VALID_TOKEN_SERVICE, postData);
            if (data.Success) {
                auth.login(data.Data);
                setIsLogin(true);
                setLoading(false);
                api.defaults.headers.common['authorization'] = cookie.token;
            }
            else {
                setIsLogin(false);
                setLoading(false);
                removeToken('token');
                delete api.defaults.headers.common["authorization"];
            }
        } catch (error) {
            setIsLogin(false);
            setLoading(false);
            removeToken('token');
            delete api.defaults.headers.common["authorization"];
        }
    }


    if (loading) {
        return <Loading isFull={true} />
    } else {
        if (!auth.user.id && !isLogin) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to="/login" state={{ from: location }} />;
        }

        return children;
    }
}

export default RequireAuth;