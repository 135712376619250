import { useTranslation } from 'react-i18next'
import { Form } from '.'

const LeftContainer = () => {
    const { t } = useTranslation();
    return (
        <div className="w-full flex flex-col gap-y-10 justify-center">
            <div>
                <img src="/images/logo.webp" alt="Pergotech Logo" className="w-44" />
            </div>
            <div className="pl-3">
                <h1 className="text-lg font-semibold">{t('login')}</h1>
                <div className="mt-5">
                    <Form />
                </div>
            </div>
        </div>
    )
}

export default LeftContainer
