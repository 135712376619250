import { FiHome, FiUser, FiFileText, FiSettings } from "react-icons/fi";
import { AiOutlineBank } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAuth } from "../../../contexts/auth";
import { MenuMockup } from "../../../mockup";
import { MenuIconTypes } from "../../../types/menu";
import { Roles } from "../../../types/roles";

const Menu = () => {
  const {
    user: { roles },
  } = useAuth();
  const { t } = useTranslation();
  const isAdmin = roles.find((role) => role.name === "Admin");
  const { pathname } = useLocation();
  const splitLocation: string =
    pathname.split("/").length > 0 ? pathname.split("/")[1] : "";

  const GetIcon = (key: MenuIconTypes) => {
    switch (key) {
      case MenuIconTypes.HOME:
        return <FiHome size={25} />;
      case MenuIconTypes.USER:
        return <FiUser size={25} />;
      case MenuIconTypes.CATALOG:
        return <FiFileText size={25} />;
      case MenuIconTypes.SETTING:
        return <FiSettings size={25} />;
      case MenuIconTypes.BANK:
        return <AiOutlineBank size={25} />;
    }
  };

  return (
    <nav>
      <ul className="flex flex-col gap-y-2">
        {MenuMockup.map((menu) => {
          if (isAdmin && menu.role === Roles.ADMIN) {
            return (
              <li key={`menu-${menu.name}`}>
                <NavLink
                  to={menu.to}
                  className={`relative flex items-center gap-x-3 p-3 
                                rounded transition 
                                ${
                                  `/${splitLocation}` === menu.to
                                    ? "text-white shadow-brand-gradiant bg-gradient-to-l to-brand-purple from-brand-pink z-10"
                                    : "hover:bg-gray-100"
                                }`}
                >
                  {GetIcon(menu.icon)}
                  <span>{t(menu.name)}</span>
                </NavLink>
              </li>
            );
          } else if (menu.role === Roles.USER) {
            return (
              <li key={`menu-${menu.name}`}>
                <NavLink
                  to={menu.to}
                  className={`relative flex items-center gap-x-3 p-3 
                                rounded transition 
                                ${
                                  `/${splitLocation}` === menu.to
                                    ? "text-white shadow-brand-gradiant bg-gradient-to-l to-brand-purple from-brand-pink z-10"
                                    : "hover:bg-gray-100"
                                }`}
                >
                  {GetIcon(menu.icon)}
                  <span>{t(menu.name)}</span>
                </NavLink>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </nav>
  );
};

export default Menu;
