import React from 'react';
import { UserDeclare } from '../types';

interface AuthContextType {
    user: UserDeclare.UserModel;
    login: (user: UserDeclare.UserModel, callback?: VoidFunction) => void;
    signout: (callback?: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const emptyUser: UserDeclare.UserModel = {} as UserDeclare.UserModel;
    const [user, setUser] = React.useState<UserDeclare.UserModel>(emptyUser);

    const login = (user: UserDeclare.UserModel, callback?: VoidFunction) => {
        setUser(user);
        if (callback) {
            callback();
        }
    };

    const signout = (callback?: VoidFunction) => {
        setUser(emptyUser);
        if (callback) {
            callback();
        }
    };

    const value: AuthContextType = { user, login, signout };

    return <AuthContext.Provider value={value} > {children}</AuthContext.Provider >;
}

const useAuth = () => {
    return React.useContext(AuthContext);
}

export { AuthProvider, useAuth }