import React from 'react'
import { FiCheck } from 'react-icons/fi'


type CheckboxProps = {
    Id: string,
    title: string,
    checked?: boolean,
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    setFieldValue?: React.MouseEventHandler<HTMLDivElement>;
    error?: boolean | string;
    errorMessage?: string
}


const Checkbox: React.FunctionComponent<CheckboxProps> = ({ Id, title, checked, onChange, setFieldValue, error, errorMessage }) => {
    return (
        <div className="flex flex-wrap items-center relative">
            <input id={Id} name={Id} type="checkbox" checked={checked} onChange={onChange} className="opacity-0 w-0 h-0" />
            <div className={`w-6 h-6 transition border flex items-center justify-center relative ${checked ? "bg-black" : ""}`}
                onClick={setFieldValue}
            >
                {checked && <FiCheck className="text-white" />}
            </div>
            <label htmlFor={Id} className="ml-2 text-sm font-light">
                {title}
            </label>
        </div >
    )
}

export default Checkbox
