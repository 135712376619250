import React, { FunctionComponent } from "react"

type TextareaProps = {
    Id: string,
    title: string,
    value: string,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
    error?: boolean | string;
    errorMessage?: string
}

const Textarea: FunctionComponent<TextareaProps> = ({ Id, title, value, error, errorMessage, onChange, onBlur, }) => {
    return (
        <div className="flex flex-col relative">
            <label htmlFor={Id} className="absolute left-2 -top-2 bg-white max-w-max text-sm px-3 font-light">
                {title}
            </label>
            <textarea
                className="border w-full rounded-sm p-4 focus:outline-none focus:border-purple-700 transition-colors h-40 resize-none"
                onChange={onChange}
                onBlur={onBlur}
                name={Id} id={Id} value={value} />
            {error &&
                <div className="mt-1">
                    <span className="text-xs text-red-600">{errorMessage}</span>
                </div>
            }
        </div>
    )
}

export default Textarea
