import React from "react";
import { Layout } from "../../components";

const BankMock = [
  {
    title: "ZİRAAT BANKASI / USD ACCOUNT $",
    keys: [
      { "Account No": "68331871-5002" },
      { "Account Name/due date": "USD –UNITED STATES DOLLAR" },
      { Branch: "KÜÇÜKYALI (736)" },
      { IBAN: "TR45 0001 0007 3668 3318 7150 02" },
      { Title: "ALTUN TENTE  SAN.TİC.LTD.ŞTİ" },
    ],
  },
  {
    title: "ZİRAAT BANKASI / EURO ACCOUNT €",
    keys: [
      { "Account No": "68331871-5003" },
      { "Account Name/due date": "EURO-EUROPEAN CURRENCY" },
      { Branch: "KÜÇÜKYALI (736)" },
      { IBAN: "TR18 0001 0007 3668 3318 7150 03" },
      { Title: "ALTUN TENTE  SAN.TİC.LTD.ŞTİ" },
    ],
  },
  {
    title: "YAPIKREDİ / SWISS FRANK ACCOUNT CHF",
    keys: [
      { "Account No": "57161551" },
      { "Account Name/due date": "CHF–SWISS FRANK" },
      { Branch: "MALTEPE ŞUBESİ (206)" },
      { IBAN: "TR09 0006 7010 0000 0057 1615 51" },
    ],
  },
  {
    title: "YAPIKREDİ BANK / STERLING ACCOUNT GBP",
    keys: [
      { IBAN: "TR72 0006 7010 0000 0042 2679 10" },
      { "Bank Code": "067" },
      { "Swift Code": "YAPITRISA" },
      { Title: "ALTUN TENTE  SAN.TİC.LTD.ŞTİ" },
    ],
  },
  {
    title: "VAKIFBANK / STERLING ACCOUNT GBP",
    keys: [
      { "Account No": "00158048016325357" },
      { IBAN: "TR050001500158048016325357" },
      { "Bank Code": "0015" },
      { "Swift Code": "TVBATR2A" },
    ],
  },
];

const BankInfoPage: React.FunctionComponent = () => {
  return (
    <>
      <Layout withoutMain>
        <div className="px-5 relative mt-10 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-5">
          {BankMock.map((bank) => {
            return (
              <div
                className="bg-white p-5 shadow-md rounded-md flex flex-col gap-2"
                key={bank.title}
              >
                <h1 className="text-lg font-bold mb-5">{bank.title}</h1>
                {bank.keys.map((key, index) => {
                  return (
                    <p className="text-sm" key={`key-${bank.title}}-${index}`}>
                      <strong className="font-semibold">
                        {Object.keys(key)} :{" "}
                      </strong>
                      <span>{Object.values(key)}</span>
                    </p>
                  );
                })}
              </div>
            );
          })}
          <div className="bg-white shadow-md rounded-md flex flex-col gap-2">
            <img src="/images/company_info.jpg" alt="Company Info" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BankInfoPage;
