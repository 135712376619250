import React, { FunctionComponent } from 'react'
import moment from 'moment'
import "moment/min/locales";
import { useTranslation } from 'react-i18next'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { BsFilePdf } from 'react-icons/bs'
import { CatalogModel } from '../../types/catalog'
import { FiArrowLeft } from 'react-icons/fi'
import { Alert, Button, Loading } from '..'
import { AlertType, ResponseModel } from '../../types'
import { Roles } from '../../types/roles'
import { useAuth } from '../../contexts/auth';
import AddFile from './addfile'
import FileList from './filelist'
import api from '../../repository'

type CardProps<T> = {
    item: T,
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>
}

const Card: FunctionComponent<CardProps<CatalogModel.Catalog>> = ({ item, setRefreshList }) => {
    const emptyCatalog = {} as CatalogModel.Catalog;
    const { user: { roles } } = useAuth();
    const { t, i18n } = useTranslation();
    const isAdmin = roles.find((role) => role.name === Roles.ADMIN);
    const [active, setActive] = React.useState<boolean>(false);
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const [modal, setModal] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [fileLoading, setFileLoading] = React.useState<boolean>(false);
    const [detail, setDetail] = React.useState<CatalogModel.Catalog>(emptyCatalog);

    moment.locale(i18n.language);

    React.useEffect(() => {
        if ((active && detail.id === undefined) || refresh) {
            GetDetail();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, refresh]);

    const GetDetail = async () => {
        try {
            const { data } = await api.get<ResponseModel<CatalogModel.Catalog>>(`${process.env.REACT_APP_CATALOG_SERVICE}/${item.id}`);
            if (data.Success) {
                setDetail(data.Data);
                setLoading(false);
                setRefresh(false);
            }
        } catch (error) {
            setLoading(false);
            setRefresh(false);
        }
    }

    const deleteCatalog = async () => {
        try {
            setFileLoading(true);
            const { data } = await api.delete<ResponseModel<CatalogModel.Catalog>>(`${process.env.REACT_APP_CATALOG_SERVICE}/${item.id}`);
            if (data.Success) {
                setActive(false);
                setFileLoading(false);
                setRefreshList(true);
            }
        } catch (error) {
        }
    }

    return (
        <>
            <div className="border p-2 rounded-sm" onClick={() => setActive((prev) => !prev)}>
                <div className="relative bg-gray-300 flex items-center justify-center w-full h-36 cursor-pointer">
                    <HiOutlineDotsVertical size={25} className="absolute right-1 top-3" />
                    <BsFilePdf size={40} />
                </div>
                <div className="mt-4">
                    <p className="font-semibold mb-1 cursor-pointer">{item.name}</p>
                    {item.downloads.filter((i) => !i.isdeleted).length > 0 &&
                        <p className="text-xs text-gray-700">
                            {t('languages_availabe', { count: item.downloads.filter((i) => !i.isdeleted).length })}
                        </p>}
                    <p className="text-xs text-gray-400 mt-2">{t('last_updated')} {moment(item.createdAt).startOf('hour').fromNow()}</p>
                </div>
            </div>
            <div className={`fixed transition-transform duration-500 transform  ${active ? "-translate-x-2/4 opacity-25" : "translate-x-full opacity-0"} right-0 top-0 bg-black h-full w-2/3 z-50`} onClick={() => setActive(false)} />
            <div className={`fixed transition duration-500 transform  ${active ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"} right-0 top-0 bg-white h-full w-1/3 z-50 shadow-md p-5`}>
                {fileLoading && <div className="absolute top-0 left-0 w-full h-full z-20">
                    <div className="absolute bg-black opacity-25 w-full h-full z-0" />
                    <Loading />
                </div>}
                <div className="flex justify-between gap-x-4 items-center pb-3 border-b">
                    <div className="p-2 rounded-full bg-white shadow hover:bg-gray-50" onClick={() => setActive(false)}>
                        <FiArrowLeft size={30} className="cursor-pointer" />
                    </div>
                    <h2 className="text-2xl font-bold">{item.name}</h2>
                    {isAdmin && <Button title={t('add_file')} appearance="link" onClick={() => setModal(true)} />}
                    {isAdmin && <Button title={t('delete')} appearance="link_danger" onClick={() => deleteCatalog()} />}
                </div>
                <div className="mt-5">
                    <p className="text-sm">
                        {item.description}
                    </p>
                    <div className="mt-4">
                        {!loading ?
                            <div className={'filelist'}>
                                {detail?.downloads?.length > 0 ?
                                    <FileList items={detail?.downloads} setRefresh={setRefresh} />
                                    :
                                    <Alert type={AlertType.INFO} title={t('file_not_attached')} relative={true} />
                                }
                            </div> :
                            <Loading />}
                    </div>
                </div>
            </div>
            <AddFile id={item.id} title={item.name} active={modal} setActive={setModal} />
        </>
    )
}

export default Card
