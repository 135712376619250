import Menu from './menu'

const Aside = () => {
    return (
        <div className="p-3 bg-white shadow-lg h-full">
            <div className="my-5">
                <img src="/images/logo.webp" alt="Pergotech Logo" className="w-44" />
            </div>
            <Menu />
        </div>
    )
}

export default Aside
