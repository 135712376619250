import React from 'react'
import { Layout } from '../../components'
import { List } from './components'


const UserPage: React.FunctionComponent = () => {
    return (
        <>
            <Layout>
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-b border-gray-200 ">
                                <List />
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default UserPage

