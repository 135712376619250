import React from 'react'
import { Button, Card, Layout, Loading, UnAuthorized } from '../../../components';
import { useParams } from "react-router-dom";
import { ResponseModel } from '../../../types';
import { CatalogModel } from '../../../types/catalog';
import api from '../../../repository';
import { useAuth } from '../../../contexts/auth';
import { Roles } from '../../../types/roles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';

const DetailList = () => {
    const navigate = useNavigate();
    const { user: { roles } } = useAuth();
    const { type } = useParams();
    const { t } = useTranslation();
    const isAdmin = roles.find((role) => role.name === Roles.ADMIN);
    const emptyCatalogList: CatalogModel.Catalog[] = [] as CatalogModel.Catalog[];
    const [loading, setLoading] = React.useState<boolean>(true);
    const [refresh, setRefresh] = React.useState<boolean>(true);
    const [unauthorized, setUnauthorized] = React.useState<boolean>(false);
    const [catalogs, setCatalogs] = React.useState<CatalogModel.Catalog[]>(emptyCatalogList);


    React.useEffect(() => {
        if (refresh) {
            GetCatalog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const GetCatalog = async () => {
        try {
            const query: CatalogModel.CatalogQuery = {
                type: type ? type : ''
            }
            const { data } = await api.post<ResponseModel<CatalogModel.Catalog[]>>(process.env.REACT_APP_CATALOG_LIST_SERVICE, query);
            if (data.Success) {
                setCatalogs(data.Data);
            }
            setRefresh(false);
            setLoading(false);
        } catch (error) {
            setUnauthorized(true);
            setLoading(false);
            setRefresh(false);
        }
    }

    return (
        <>
            <Layout>
                <div className="flex justify-between">
                    <h1 className="text-2xl font-semibold mb-5">{type}</h1>
                    <div className="flex flex-wrap gap-x-3">
                        {isAdmin && <Button title={t('add_catalog')} onClick={() => navigate(`/catalog/create/${type}`)} />}
                        <div className="flex flex-wrap gap-x-3">
                            <Button title={t('turn_back')} appearance="secondary" onClick={() => navigate('/catalog')}>
                                <FiArrowLeft size={20} />
                                <span className="ml-1">{t('turn_back')}</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="w-full mt-10">
                    {!loading ?
                        <>
                            {!unauthorized ?
                                <div className="grid grid-flow-row grid-cols-4 gap-5 mt-10">
                                    {catalogs.map((catalog) => {
                                        return (<Card item={catalog} setRefreshList={setRefresh} key={`catalog-${catalog.id}-${catalog.name}`} />)
                                    })}
                                </div> :
                                <UnAuthorized />
                            }
                        </> :
                        <Loading />
                    }
                </div>
            </Layout>
        </>
    )
}

export default DetailList
