import React, { FunctionComponent } from 'react'
import cn from 'classnames'
import styles from './button.module.css'

type ButtonProps = {
    title?: string;
    appearance?:
    "primary"
    | "secondary"
    | "danger"
    | "success"
    | "link"
    | "link_danger";
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button: FunctionComponent<ButtonProps> = ({ title, children, onClick, appearance = "primary" }) => {
    return (
        <button type="button" className={cn(styles.base, styles[appearance])} onClick={onClick}>
            {children ? children : <span>{title}</span>}
        </button >
    )
}

export default Button
