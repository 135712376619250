import { Route, Routes } from "react-router";
import RequireAuth from "../middleware/loginCheck";
import {
  CatalogCreatePage,
  CatalogPage,
  SystemDownloadListPage,
  SystemErrorListPage,
  HomePage,
  LoginPage,
  NotFound,
  SystemPage,
  UserCreatePage,
  UserEditPage,
  UserPage,
  SystemUserListPage,
} from "../pages";
import BankInfoPage from "../pages/bank_info";
import DetailList from "../pages/catalog/components/detaillist";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/bankinfo"
        element={
          <RequireAuth>
            <BankInfoPage />
          </RequireAuth>
        }
      />
      <Route
        path="/catalog"
        element={
          <RequireAuth>
            <CatalogPage />
          </RequireAuth>
        }
      />
      <Route
        path="/catalog/:type"
        element={
          <RequireAuth>
            <DetailList />
          </RequireAuth>
        }
      />
      <Route
        path="/catalog/create"
        element={
          <RequireAuth>
            <CatalogCreatePage />
          </RequireAuth>
        }
      />
      <Route
        path="/catalog/create/:type"
        element={
          <RequireAuth>
            <CatalogCreatePage />
          </RequireAuth>
        }
      />
      <Route
        path="/user"
        element={
          <RequireAuth>
            <UserPage />
          </RequireAuth>
        }
      />
      <Route
        path="/user/create"
        element={
          <RequireAuth>
            <UserCreatePage />
          </RequireAuth>
        }
      />
      <Route
        path="/user/edit/:userid"
        element={
          <RequireAuth>
            <UserEditPage />
          </RequireAuth>
        }
      />
      <Route
        path="/system"
        element={
          <RequireAuth>
            <SystemPage />
          </RequireAuth>
        }
      />
      <Route
        path="/system/downloadlist"
        element={
          <RequireAuth>
            <SystemDownloadListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/system/errorlist"
        element={
          <RequireAuth>
            <SystemErrorListPage />
          </RequireAuth>
        }
      />
      <Route
        path="/system/userlist"
        element={
          <RequireAuth>
            <SystemUserListPage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="*"
        element={
          <RequireAuth>
            <NotFound />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Router;
