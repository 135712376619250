export const TRANSLATIONS_TR = {
  our_dealers: "Our dealers ;",
  our_dealers_text:
    "It is a platform where technical details, models, materials library to facilitate operational processes, data that will facilitate project processes, and more data can be accessed. Contact us to enter this platform that will provide many advantages.",
  login: "Giriş",
  field_cannot_be_blank: "Bu alan boş bırakılamaz",
  valid_emailaddress_error: "Geçerli bir mail adresi giriniz",
  login_success_text: "Giriş başarılı yönlendiriliyorsunuz",
  record_created: "Kayıt Oluşturuldu",
  username: "Kullanıcı Adı",
  password: "Şifre",
  login_button_text: "Giriş Yap",
  dashboard: "Gösterge Paneli",
  users: "Kullanıcılar",
  catalogs: "Dökümanlar",
  system_settings: "Sistem Ayarları",
  logout: "Çıkış Yap",
  add_new: "Yeni Ekle",
  name: "İsim",
  type: "Tip",
  size: "Boyut",
  name_surname: "İsim Soyisim",
  status: "Durum",
  role: "Rol",
  edit: "Düzenle",
  active: "Aktif",
  passive: "Pasif",
  add_new_user: "Yeni Kullanıcı Ekle",
  turn_back: "Geri Dön",
  add: "Ekle",
  email: "E-Posta",
  save: "Kaydet",
  updated_record: "Kayıt Güncellendi",
  registration_deleted: "Kayıt Silindi",
  user: "Kullanıcı",
  delete: "Sil",
  add_catalog: "Döküman Ekle",
  languages_availabe: "{{count}} Farklı Döküman Mevcut",
  last_updated: "Son Güncellenme :",
  add_file: "Dosya Ekle",
  file_not_attached: "Dosya eklenmedi",
  attach_file: "Lütfen dosya ekleyiniz",
  select_language: "Dil Seçiniz",
  catalog_added: "Döküman Eklendi",
  add_new_catalog: "Yeni Katalog Ekle",
  catalog_download_list: "Döküman İndirme Listesi",
  message: "Mesaj",
  date: "Tarih",
  id: "Id",
  ip: "Ip",
  catalog_error_list: "Sistem Hataları",
  source: "Kaynak",
  user_log_list: "Kullanıcı Giriş Logları",
  forgot_password: "Şifremi Unuttum",
  change_password: "Şifre Değiştir",
  updated_password: "Şifre Güncellendi",
  modal_warning_text:
    "Bu platformdaki dökümanları ve bilgileri 3. şahıslarla paylaşmak yasaktır.",
  main_file: "Ana Klasör",
  not_accept: "Kabul Etmiyorum",
  agree: "Kabul Ediyorum",
  bank_info: "Banka Bilgileri",
  certificates: "Sertifikalar",
  models_drawing: "MODELLER - ÇİZİMLER (2D-3D)",
  catalog_broschure: "KATALOG-BROŞÜR",
  installation_files: "KURULUM DOSYALARI",
  technical_data: "TEKNİK BİLGİ",
  formulas: "HESAPLAMALAR-FORMÜLLER",
  material_info: "MATERİAL BİLGİ",
  pergo_docs_info: "PERGOTECH GLOBAL DAİR DÖKÜMANLAR",
};
