import React from 'react'
import { useTranslation } from 'react-i18next'
import { Layout } from '../../components'
import { List } from './components'

const SystemPage: React.FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <Layout>
                <div className="flex justify-between">
                    <h1 className="text-2xl font-semibold mb-5">{t('system_settings')}</h1>
                </div>
                <div className="w-full mt-10">
                    <List />
                </div>
            </Layout>
        </>
    )
}

export default SystemPage
