import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router';
import { Button, Layout } from '../../components'
import { useAuth } from '../../contexts/auth';
import { Roles } from '../../types/roles';
import { List } from './components';

const CatalogPage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user: { roles } } = useAuth();
    const isAdmin = roles.find((role) => role.name === Roles.ADMIN);

    return (
        <>
            <Layout>
                <div className="flex justify-between">
                    <h1 className="text-2xl font-semibold mb-5">{t('catalogs')}</h1>
                    {isAdmin && <Button title={t('add_catalog')} onClick={() => navigate('/catalog/create')} />}
                </div>
                <div className="w-full mt-10">
                    <List />
                </div>
            </Layout>
        </>
    )
}

export default CatalogPage
