import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import * as Yup from "yup";
import { Alert, Button, Checkbox, Input } from '../../../components'
import { AddnewUserAttributes, ResponseModel, UserDeclare } from '../../../types'
import { AlertType } from '../../../types/alert'
import api from '../../../repository'
import { ChangePasswordAtrributes } from '../../../types/userModel';


type FormProps = {
    user: AddnewUserAttributes
}

const Form: React.FunctionComponent<FormProps> = ({ user }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [alertActive, setAlertActive] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);
    const [changePasswordLoading, setChangePasswordLoading] = React.useState<boolean>(false);

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('field_cannot_be_blank')),
        fullname: Yup.string().required(t('field_cannot_be_blank')),
        email: Yup.string().email(t('valid_emailaddress_error')).required(t('field_cannot_be_blank')),
    });

    React.useEffect(() => {
        if (alertActive)
            setTimeout(() => { setAlertActive(false) }, 1450);

    }, [alertActive]);

    const onSubmit = async (values: AddnewUserAttributes, resetForm: any) => {
        try {
            setLoading(true);
            const { data } = await api
                .put<ResponseModel<UserDeclare.UserModel>>(`${process.env.REACT_APP_USER_SERVICE}/${user.id}`, values);

            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('updated_record'));
                setAlertActive(true);
            }
            setLoading(false);
            resetForm();
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setLoading(false);
        }
    };

    const changePassword = async () => {
        try {
            const fetchData: ChangePasswordAtrributes = {
                id: Number(user.id),
                password
            }
            setChangePasswordLoading(true);
            const { data } = await api
                .post<ResponseModel<null>>(`${process.env.REACT_APP_USER_CHANGE_PASSWORD_SERVICE}/${user.id}`, fetchData);

            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('updated_password'));
                setAlertActive(true);
            }
            setChangePasswordLoading(false);
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setChangePasswordLoading(false);
        }


        // Reset
        setPassword('');
    }

    const initialValues: AddnewUserAttributes = {
        id: user.id,
        username: user.username,
        password: user.password,
        fullname: user.fullname,
        email: user.email,
        isactive: user.isactive
    }
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue
                }) => (
                    <>
                        <form className="w-full grid grid-flow-row grid-cols-2 gap-8 mt-10" onSubmit={handleSubmit}>
                            <Input title={t('username')} Id={"username"} type="text" value={values.username}
                                onChange={handleChange} onBlur={handleBlur}
                                error={touched.username && errors.username} errorMessage={errors.username} />

                            <Input title={t('name_surname')} Id={"fullname"} type="text" value={values.fullname}
                                onChange={handleChange} onBlur={handleBlur}
                                error={touched.fullname && errors.fullname} errorMessage={errors.fullname} />

                            <Input title={t('email')} Id={"email"} type="text" value={values.email}
                                onChange={handleChange} onBlur={handleBlur}
                                error={touched.email && errors.email} errorMessage={errors.email} />

                            <div className='grid grid-cols-3 gap-6'>
                                <div className='col-span-2'>
                                    <Input title={t('password')} Id={"password"} type="password" value={password}
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                        }} />
                                </div>
                                <Button onClick={() => changePassword()} >
                                    {!changePasswordLoading ?
                                        <span className='text-xs'>{t('change_password')}</span> :
                                        <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                    }
                                </Button>
                            </div>
                            <Checkbox title={t('active')} Id={"isactive"}
                                checked={values.isactive}
                                setFieldValue={() => setFieldValue("isactive", !values.isactive)}
                                onChange={handleChange}
                                error={touched.isactive && errors.isactive} errorMessage={errors.isactive} />

                            <div className="col-span-2 flex justify-end">
                                <Button onClick={() => handleSubmit()}>
                                    {!loading ?
                                        <span>{t('save')}</span> :
                                        <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                    }
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </Formik>
            {alertActive && <Alert title="User" text={alertMessage} type={alertType} />}
        </>
    )
}

export default Form

