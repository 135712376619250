import { Roles } from "./roles";

export interface Menu {
  name: string;
  to: string;
  icon: MenuIconTypes;
  role: Roles;
}
export enum MenuIconTypes {
  HOME = "FiHome",
  USER = "FiUser",
  CATALOG = "FiFileText",
  SETTING = "FiSettings",
  BANK = "AiOutlineBank",
}
