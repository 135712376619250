
const RightContainer = () => {

    return (
        <>
            <div className="relative rounded-tl-2xl rounded-bl-2xl">
                <div className="bg-black opacity-50 w-full h-full absolute rounded-tl-2xl rounded-bl-2xl" />
                <img src={'https://files.pergotech.com.tr/content/images/sliders/home-banner-slider-2.webp'}
                    alt="b2b Login" className="w-full h-full object-cover object-top rounded-tl-2xl rounded-bl-2xl" />
            </div>
            <div className="absolute top-8 px-5" style={{ maxWidth: 250 }}>
                <h2 className="text-white text-7xl font-medium mb-4">B2B</h2>
                <p className="text-lg text-white font-light">
                    It is a special platform for dealers.
                </p>
            </div>
        </>
    )
}

export default RightContainer
