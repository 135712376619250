import React from "react"

type LoadingProps = {
    isFull?: boolean;
    size?: string;
    color?: string;
}

const Loading: React.FunctionComponent<LoadingProps> = ({ isFull, color, size = "h-32 w-32" }) => {
    return (
        <div className={`flex w-full ${isFull ? "h-screen" : size ? "h-full" : "h-full py-16"} justify-center items-center`}>
            <div
                className={`animate-spin rounded-full ${size} border-b-2 ${color ? color : "border-gray-900"}`}
            ></div>
        </div>
    )
}

export default Loading
