import React from 'react'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi'
import { useNavigate, useParams } from 'react-router'
import * as Yup from "yup";
import { Alert, Button, Textarea, Checkbox, Input, Layout } from '../../../components'
import { ResponseModel, AlertType } from '../../../types';
import { CatalogModel } from '../../../types/catalog';
import api from '../../../repository'

const CatalogCreatePage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { type } = useParams();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [alertActive, setAlertActive] = React.useState<boolean>(false);
    const [alertMessage, setAlertMessage] = React.useState<string>('');
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('field_cannot_be_blank')),
    });

    React.useEffect(() => {
        if (alertActive)
            setTimeout(() => { setAlertActive(false) }, 1450);

    }, [alertActive])

    const onSubmit = async (values: CatalogModel.AddnewCatalogAttributes, resetForm: any) => {
        try {
            setLoading(true);
            const { data } = await api.post<ResponseModel<CatalogModel.Catalog>>(process.env.REACT_APP_CATALOG_CREATE_SERVICE, values);
            if (data.Success) {
                setAlertType(AlertType.SUCCESS);
                setAlertMessage(t('catalog_added'));
                setAlertActive(true);
            }
            setLoading(false);
            resetForm();
        } catch (err: any) {
            if (err.response) {
                setAlertType(AlertType.ERROR);
                setAlertMessage(err.response.data.Message);
                setAlertActive(true);
            }
            setLoading(false);
        }
    };

    const initialValues: CatalogModel.AddnewCatalogAttributes = {
        name: '',
        description: '',
        type: type ? type : '',
        isactive: false
    }
    return (
        <>
            <Layout>
                <div className="flex flex-col">
                    <div className="flex justify-between mb-3">
                        <h1 className="text-2xl font-semibold mb-5">{t('add_new_catalog')}</h1>
                        <div className="flex flex-wrap gap-x-3">
                            <Button title={t('turn_back')} appearance="secondary" onClick={() => navigate('/catalog')}>
                                <FiArrowLeft size={20} />
                                <span className="ml-1">{t('turn_back')}</span>
                            </Button>
                        </div>
                    </div>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            values,
                            errors,
                            touched,
                            setFieldValue
                        }) => (
                            <>
                                <form className="w-full grid grid-flow-row grid-cols-2 gap-8 mt-10" onSubmit={handleSubmit}>
                                    <div className="col-span-2 flex flex-col gap-y-8">
                                        <Input title={t('name')} Id={"name"} type="text" value={values.name}
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={touched.name && errors.name} errorMessage={errors.name} />
                                        <Input title={t('main_file')} Id={"type"} type="text" value={values.type}
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={touched.type && errors.type} errorMessage={errors.type} />
                                        <Textarea title={"Description"} Id={"description"} value={values.description}
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={touched.description && errors.description} errorMessage={errors.description} />
                                    </div>
                                    <Checkbox title={t('active')} Id={"isactive"}
                                        checked={values.isactive}
                                        setFieldValue={() => setFieldValue("isactive", !values.isactive)}
                                        onChange={handleChange}
                                        error={touched.isactive && errors.isactive} errorMessage={errors.isactive} />

                                    <div className="col-span-2 flex justify-end">
                                        <Button onClick={() => handleSubmit()}>
                                            {!loading ?
                                                <span>{t('save')}</span> :
                                                <AiOutlineLoading3Quarters size={20} className="animate-spin" />
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </>
                        )}
                    </Formik>
                    {alertActive && <Alert title="Catalog" text={alertMessage} type={alertType} />}
                </div>
            </Layout>
        </>
    )
}

export default CatalogCreatePage

